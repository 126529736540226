import {Injectable} from '@angular/core';
import {AlimentoService} from '@app/abstraction/data/services/alimento.service';
import {AlimentoStore} from '@app/abstraction/data/stores/alimento.store';
import {CategoriaFiltro} from '@app/abstraction/domain/interfaces/filtros/categoria-filtro.interface';

import {AlimentoFiltro} from '../interfaces/filtros/alimento-filtro.interface';


@Injectable({providedIn: 'root'})
export class AlimentoFacade {
  get alimentos() {
    return this.store.alimentos;
  }
  get categorias() {
    return this.store.categorias;
  }
  constructor(
      private store: AlimentoStore,
      private service: AlimentoService,
  ) {}
  async buscarTodosAlimentos(filtro?: AlimentoFiltro) {
    const alimentos = await this.service.buscarTodosAlimentos(filtro);
    if (alimentos) {
      this.store.adicionarRangeAlimentos(alimentos);
    }
  }
  async buscarTodasCategorias(filtro?: CategoriaFiltro) {
    const categorias = await this.service.buscarTodosCategorias(filtro);
    if (categorias) {
      this.store.adicionarRangeCategorias(categorias);
    }
  }
  limparListaAlimentos() {
    this.store.alimentos = null;
  }
  limparListaCategorias() {
    this.store.categorias = null;
  }
}