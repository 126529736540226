import {Pipe, PipeTransform} from '@angular/core';
import {Refeicao} from '@app/abstraction/domain/entities/refeicao.entity';

@Pipe({name: 'horarioRefeicao'})
export class HorarioRefeicaoPipe implements PipeTransform {
  transform(refeicao: Refeicao): any {
    // seconds time to hour formated 00h00

    const hours = Math.floor(refeicao.horario / 3600);
    const minutes = Math.floor((refeicao.horario % 3600) / 60);

    return `${hours.toString().padStart(2, '0')}h${
        minutes.toString().padStart(2, '0')}`;
  }
}
