import {Injectable} from '@angular/core';
import {ExercicioListagem} from '@app/abstraction/domain/interfaces/listagem/exercicio-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExercicioStore {
  private readonly _exercicios = new BehaviorSubject<ExercicioListagem[]>(null);

  get exercicios(): ExercicioListagem[] {
    return this._exercicios.getValue();
  }
  set exercicios(exercicios: ExercicioListagem[]) {
    this._exercicios.next(exercicios);
  }
  get exercicios$() {
    return this._exercicios.asObservable();
  }
  adicionar(exercicio: ExercicioListagem) {
    if (!this.exercicios) {
      this.exercicios = [];
    }
    this.exercicios.push(exercicio);
  }
  adicionarRange(exercicios: ExercicioListagem[]) {
    if (!this.exercicios) {
      this.exercicios = [];
    }

    exercicios.forEach((exercicio) => {
      if (!this.exercicios.some((c) => c.id === exercicio.id)) {
        this.exercicios.push(exercicio);
      }
    });
  }
}
