import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlimentoFacade} from '@app/abstraction/domain/facades/alimento.facade';
import {AlimentoFiltro} from '@app/abstraction/domain/interfaces/filtros/alimento-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TipoOrdem} from '@app/shared/enums/tipo-ordem.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {environment} from 'environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-alimento-busca-rapida',
  templateUrl: './alimento-busca-rapida.component.html',
})
export class AlimentoBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @Input() mostrarModal: boolean;
  @Input() filtro = <AlimentoFiltro>{};
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itemSelecionado: any;

  get dados() {
    return this.alimentoFacade.alimentos;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop();
  }
  constructor(
      private alimentoFacade: AlimentoFacade,
      private deviceDetectorFacade: DeviceDetectorService,
  ) {
    super();
  }
  private buscarAlimentosComFiltro() {
    this.alimentoFacade.buscarTodosAlimentos({
      pagina: this.pagina,
      limite: this.limite,
      nome: this.filtro.nome,
      tipoOrdem: TipoOrdem.Ascendente,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'nome',
        name: 'Nome',
      },
      {
        field: 'categoriaNome',
        name: 'Categoria',
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro.nome = null;
    this.alimentoFacade.limparListaAlimentos();
    this.buscarAlimentosComFiltro();
  }
  buscarAlimentosProximaPagina() {
    this.pagina++;
    this.buscarAlimentosComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.alimentoFacade.limparListaAlimentos();
    this.buscarAlimentosComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.alimentoFacade.limparListaAlimentos();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  buscarImagem(caminhoImagem: string) {
    return `${environment.apiBaseUrl}${caminhoImagem}`
  }
}
