import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ExercicioFiltro} from '@app/abstraction/domain/interfaces/filtros/exercicio-filtro.interface';
import {ExercicioListagem} from '@app/abstraction/domain/interfaces/listagem/exercicio-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExercicioService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  buscarTodos(filtro?: ExercicioFiltro) {
    const response$ = this.client.get<ExercicioListagem[]>(
        this.geradorQueryString.gerar(`${API.Admin_Exercicios}`, filtro));
    return lastValueFrom(response$);
  }
}
