import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxCurrencyDirective, provideEnvironmentNgxCurrency} from 'ngx-currency';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AccordionModule} from 'primeng/accordion';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeTableModule} from 'primeng/treetable';

import {CustomLabelComponent} from './components/dumb/custom-label/custom-label.component';
import {AutoCompleteInputComponent} from './components/dumb/form-inputs/auto-complete-input/auto-complete-input.component';
import {CalendarInputComponent} from './components/dumb/form-inputs/calendar-input/calendar-input.component';
import {CheckboxInputComponent} from './components/dumb/form-inputs/checkbox-input/checkbox-input.component';
import {DropdownInputComponent} from './components/dumb/form-inputs/dropdown-input/dropdown-input.component';
import {MultiselectInputComponent} from './components/dumb/form-inputs/multiselect-input/multiselect-input.component';
import {NumericInputComponent} from './components/dumb/form-inputs/numeric-input/numeric-input.component';
import {PasswordInputComponent} from './components/dumb/form-inputs/password-input/password-input.component';
import {TextInputComponent} from './components/dumb/form-inputs/text-input/text-input.component';
import {TextAreaInputComponent} from './components/dumb/form-inputs/textarea-input/textarea-input.component';
import {DropdownInputNgmodelComponent} from './components/dumb/ng-model-inputs/dropdown-input-ngmodel/dropdown-input-ngmodel.component';
import {TextInputNgModelComponent} from './components/dumb/ng-model-inputs/text-input-ngmodel/text-input-ngmodel.component';
import {TextAreaInputNgModelComponent} from './components/dumb/ng-model-inputs/textarea-input-ngmodel/textarea-input-ngmodel.component';
import {TableSkeletonComponent} from './components/dumb/table-skeletons/table-skeleton.component';
import {TableComponent} from './components/dumb/table/table.component';
import {AlimentoBuscaRapidaComponent} from './components/smart/buscas-rapidas/alimento-busca-rapida/alimento-busca-rapida.component';
import {ExercicioBuscaRapidaComponent} from './components/smart/buscas-rapidas/exercicio-busca-rapida/exercicio-busca-rapida.component';
import {TituloComponent} from './components/smart/titulo/titulo.component';
import {CustomDecimalPipe} from './pipes/custom-decimal.pipe';
import {EnumLabelPipe} from './pipes/enum-label.pipe';
import {HorarioRefeicaoPipe} from './pipes/horario-refeicao.pipe';
import {TempoDescansoPipe} from './pipes/tempo-descanso.pipe';
import {UnidadeMedidaAbrevicaoPipe} from './pipes/unidade-medida-abreviacao.pipe';

@NgModule({
  imports: [...SharedModule.importModules()],
  declarations: [
    ...SharedModule.declareComponents(),
    ...SharedModule.declarePipes(),
  ],
  exports: [
    ...SharedModule.exportModules(),
    ...SharedModule.exportComponents(),
    ...SharedModule.exportPipes(),
  ],
  providers: [
    ...SharedModule.providePipes(),

    provideEnvironmentNgxCurrency({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: true,
    }),
  ],
})
export class SharedModule {
  static importModules() {
    return [
      AccordionModule,      TabViewModule,        AvatarGroupModule,
      MegaMenuModule,       AvatarModule,         DialogModule,
      BreadcrumbModule,     RadioButtonModule,    ButtonModule,
      PasswordModule,       CalendarModule,       FileUploadModule,
      CardModule,           CheckboxModule,       CommonModule,
      FormsModule,          DropdownModule,       MultiSelectModule,
      InfiniteScrollModule, SelectButtonModule,   InputNumberModule,
      ToolbarModule,        InputSwitchModule,    ConfirmDialogModule,
      InputTextareaModule,  TieredMenuModule,     MenuModule,
      ChartModule,          NgxCurrencyDirective, PanelMenuModule,
      TreeTableModule,      ReactiveFormsModule,  DividerModule,
      ScrollTopModule,      InputTextModule,      SkeletonModule,
      MenuModule,           TableModule,          TagModule,
      ToastModule,          ConfirmPopupModule,   TooltipModule,
      PanelModule,          OrderListModule,      ListboxModule,
    ];
  }
  static declareComponents() {
    return [
      AutoCompleteInputComponent,
      CalendarInputComponent,
      CheckboxInputComponent,
      CustomLabelComponent,
      DropdownInputComponent,
      DropdownInputNgmodelComponent,
      ExercicioBuscaRapidaComponent,
      MultiselectInputComponent,
      NumericInputComponent,
      PasswordInputComponent,
      TableComponent,
      TableSkeletonComponent,
      TextAreaInputComponent,
      TextInputComponent,
      TextInputNgModelComponent,
      TituloComponent,
      AlimentoBuscaRapidaComponent,
      TextAreaInputNgModelComponent,
    ];
  }
  static declarePipes() {
    return [
      CustomDecimalPipe,
      EnumLabelPipe,
      TempoDescansoPipe,
      HorarioRefeicaoPipe,
      UnidadeMedidaAbrevicaoPipe,
    ];
  }
  static exportModules() {
    return [
      AvatarModule,         DialogModule,        BreadcrumbModule,
      RadioButtonModule,    ButtonModule,        PasswordModule,
      CardModule,           CheckboxModule,      ChartModule,
      ToastModule,          CommonModule,        FormsModule,
      ConfirmDialogModule,  InputTextareaModule, ConfirmPopupModule,
      InfiniteScrollModule, FileUploadModule,    InputSwitchModule,
      InputNumberModule,    ToolbarModule,       MultiSelectModule,
      PanelMenuModule,      ReactiveFormsModule, DividerModule,
      ScrollTopModule,      InputTextModule,     SelectButtonModule,
      SkeletonModule,       AccordionModule,     TabViewModule,
      DropdownModule,       TableModule,         TagModule,
      TieredMenuModule,     MenuModule,          TooltipModule,
      PanelModule,          TreeTableModule,     CalendarModule,
      OrderListModule,      ListboxModule,
    ];
  }
  static exportComponents() {
    return [
      AutoCompleteInputComponent,
      CalendarInputComponent,
      CheckboxInputComponent,
      CustomLabelComponent,
      DropdownInputComponent,
      DropdownInputNgmodelComponent,
      ExercicioBuscaRapidaComponent,
      MultiselectInputComponent,
      NumericInputComponent,
      PasswordInputComponent,
      TableComponent,
      TableSkeletonComponent,
      TextAreaInputComponent,
      TextInputComponent,
      TextInputNgModelComponent,
      TituloComponent,
      AlimentoBuscaRapidaComponent,
      TextAreaInputNgModelComponent,
    ];
  }
  static exportPipes() {
    return [
      CustomDecimalPipe,
      EnumLabelPipe,
      TempoDescansoPipe,
      HorarioRefeicaoPipe,
      UnidadeMedidaAbrevicaoPipe,
    ];
  }
  static providePipes() {
    return [DatePipe];
  }
}
