export enum UnidadeMedida {
  Gramas = 1,
  Unidade = 2,
  Fatia = 3,
  Mililitros = 4,
}

export const UnidadeMedidaLabels = new Map<number, string>([
  [UnidadeMedida.Gramas, 'Gramas'],
  [UnidadeMedida.Unidade, 'Unidade'],
  [UnidadeMedida.Fatia, 'Fatia'],
  [UnidadeMedida.Mililitros, 'Mililitros'],
]);

export const UnidadeMedidaAbreviacao = new Map<number, string>([
  [UnidadeMedida.Gramas, 'g'],
  [UnidadeMedida.Unidade, 'un'],
  [UnidadeMedida.Fatia, 'fat'],
  [UnidadeMedida.Mililitros, 'ml'],
]);
