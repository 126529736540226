import {Injectable} from '@angular/core';
import {AlimentoListagem} from '@app/abstraction/domain/interfaces/listagem/alimento-listagem.interface';
import {CategoriaListagem} from '@app/abstraction/domain/interfaces/listagem/categoria-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AlimentoStore {
  private readonly _alimentos = new BehaviorSubject<AlimentoListagem[]>(null);
  private readonly _categorias = new BehaviorSubject<CategoriaListagem[]>(null);

  get alimentos(): AlimentoListagem[] {
    return this._alimentos.getValue();
  }
  set alimentos(alimentos: AlimentoListagem[]) {
    this._alimentos.next(alimentos);
  }
  get alimentos$() {
    return this._alimentos.asObservable();
  }

  get categorias(): CategoriaListagem[] {
    return this._categorias.getValue();
  }
  set categorias(categorias: CategoriaListagem[]) {
    this._categorias.next(categorias);
  }
  get categorias$() {
    return this._categorias.asObservable();
  }

  adicionarAlimento(alimento: AlimentoListagem) {
    if (!this.alimentos) {
      this.alimentos = [];
    }
    this.alimentos.push(alimento);
  }
  adicionarRangeAlimentos(alimentos: AlimentoListagem[]) {
    if (!this.alimentos) {
      this.alimentos = [];
    }

    alimentos.forEach((alimento) => {
      if (!this.alimentos.some((c) => c.id === alimento.id)) {
        this.alimentos.push(alimento);
      }
    });
  }

  adicionarCategoria(categoria: CategoriaListagem) {
    if (!this.categorias) {
      this.categorias = [];
    }
    this.categorias.push(categoria);
  }
  adicionarRangeCategorias(categorias: CategoriaListagem[]) {
    if (!this.categorias) {
      this.categorias = [];
    }

    categorias.forEach((categoria) => {
      if (!this.categorias.some((c) => c.id === categoria.id)) {
        this.categorias.push(categoria);
      }
    });
  }
}
