import {Pipe, PipeTransform} from '@angular/core';
import {RotinaItem} from '@app/abstraction/domain/entities/rotina-item.entity';

@Pipe({name: 'tempoDescanso'})
export class TempoDescansoPipe implements PipeTransform {
  transform(rotina: RotinaItem): any {
    // number to datetime 00:00 string formated
    return rotina.series[0].descanso ?
        new Date(rotina.series[0].descanso * 1000).toISOString().substr(14, 5) :
        '00:00';
  }
}
