// import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/abstraction/data/services/auth.service';
import {AuthStore} from '@app/abstraction/data/stores/auth.store';
import {AlterarSenhaModel} from '@app/abstraction/domain/model/alterar-senha.model';

@Injectable({providedIn: 'root'})
export class AuthFacade {
  constructor(
      private service: AuthService,
      private store: AuthStore,
      private router: Router,
  ) {}
  get isLoggedIn() {
    return this.store.hasValidAccessToken;
  }
  get usuarioLogado() {
    return this.store.usuarioLogado;
  }
  get isNutricionista() {
    return this.store.usuarioLogado.nutricionistaId
  }
  get isTreinador() {
    return this.store.usuarioLogado.treinadorId
  }
  async login(email: string, senha: string) {
    if (!this.isLoggedIn) {
      return this.service.login(email, senha).then((token) => {
        this.store.access_token = token;
        localStorage.setItem('access_token', token);
      });
    }
    return null;
  }
  async buscarUsuarioLogado() {
    this.store.usuarioLogado = await this.service.buscarUsuarioLogado();
  }
  logout() {
    this.store.access_token = null;
    this.store.usuarioLogado = null;
    localStorage.removeItem('access_token');
    this.router.navigate(['/auth/login']);
  }
  alterarSenha(alterarSenha: AlterarSenhaModel) {
    return this.service.alterarSenha(alterarSenha);
  }
}
