import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ExercicioFacade} from '@app/abstraction/domain/facades/exercicio.facade';
import {ExercicioFiltro} from '@app/abstraction/domain/interfaces/filtros/exercicio-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TipoOrdem} from '@app/shared/enums/tipo-ordem.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {environment} from 'environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-exercicio-busca-rapida',
  templateUrl: './exercicio-busca-rapida.component.html',
})
export class ExercicioBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('imagemTemp', {static: true}) imagemTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() filtro = <ExercicioFiltro>{};
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itemSelecionado: any;

  get dados() {
    return this.exercicioFacade.exercicios;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop();
  }
  constructor(
      private exercicioFacade: ExercicioFacade,
      private deviceDetectorFacade: DeviceDetectorService,
  ) {
    super();
  }
  private buscarExerciciosComFiltro() {
    this.exercicioFacade.buscarTodos({
      pagina: this.pagina,
      limite: this.limite,
      nome: this.filtro.nome,
      tipoOrdem: TipoOrdem.Ascendente,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'caminhoImagem',
        name: '',
        cellTemplate: this.imagemTemp,
        width: 250,
      },
      {
        field: 'nome',
        name: 'Nome',
      },
      {
        field: 'grupoMuscularNome',
        name: 'Grupo Muscular',
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro.nome = null;
    this.exercicioFacade.limparListaExercicios();
    this.buscarExerciciosComFiltro();
  }
  buscarExerciciosProximaPagina() {
    this.pagina++;
    this.buscarExerciciosComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.exercicioFacade.limparListaExercicios();
    this.buscarExerciciosComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.exercicioFacade.limparListaExercicios();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  buscarImagem(caminhoImagem: string) {
    return `${environment.apiBaseUrl}${caminhoImagem}`
  }
}
