
import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {GeneroLabels} from '@app/shared/enums/genero.enum';
import {TipoPessoa} from '@app/shared/enums/tipo-pessoa.enum';
import {UnidadeMedidaLabels} from '@app/shared/enums/unidade-medida.enum';
import {DropdownItem} from '@app/shared/interfaces/components/dropdown-item.interface';

@Component({
  template: '',
})
export class BaseComponent {
  tiposPessoas: DropdownItem[] = [];
  unidadesMedidas: DropdownItem[] = [];
  generos: DropdownItem[] = []

  constructor() {}
  private setarCamposInvalidos(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.setarCamposInvalidos(control);
      }
    });
  }
  montarDropdownTipoPessoa() {
    this.tiposPessoas.push(<DropdownItem>{
      texto: 'Nutricionista',
      valor: TipoPessoa.Nutricionista,
    });
    this.tiposPessoas.push(<DropdownItem>{
      texto: 'Treinador',
      valor: TipoPessoa.Treinador,
    });
  }
  montarDropdownUnidadeMedida() {
    UnidadeMedidaLabels.forEach((valor, chave) => {
      this.unidadesMedidas.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownGeneros() {
    GeneroLabels.forEach((valor, chave) => {
      this.generos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
}
