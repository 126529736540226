export enum API {
  Admin_Alimentos = 'admin/alimentos',
  Admin_Categorias = 'admin/categorias',
  Admin_Exercicios = 'admin/exercicios',
  Admin_GrupoMuscular = 'admin/grupo-muscular',

  Parceiro_Aluno = 'parceiro/aluno',
  Parceiro_Dietas_Aluno = 'parceiro/dietas/aluno',
  Parceiro_Dietas = 'parceiro/dietas',
  Parceiro_Auth_Nutricionista_Vincular_Aluno =
      'parceiro/auth/nutricionista/vincular-aluno',
  Parceiro_Auth_Treinador_Vincular_Aluno =
      'parceiro/auth/treinador/vincular-aluno',

  Parceiro_Treinos_Aluno = 'parceiro/treinos/aluno',
  Parceiro_Treinos = 'parceiro/treinos',
  Parceiro_Dashboard = 'parceiro/dashboard',

  Parceiro_Auth = 'parceiro/auth',
  Parceiro_Auth_Logar = 'parceiro/auth/logar',
  Parceiro_Auth_Logado = 'parceiro/auth/logado',
  Parceiro_Auth_Alterar_Senha = 'parceiro/auth/alterar-senha',
}