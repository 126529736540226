import {Component, TemplateRef, ViewChild} from '@angular/core';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

import {BaseComponent} from './base.component';


@Component({template: ''})
export class ListagemBaseComponent extends BaseComponent {
  @ViewChild('acoesTemp', {static: true}) acoesTemp: TemplateRef<any>;
  @ViewChild('colunasOcultasTemp', {static: true})
  colunasOcultasTemp: TemplateRef<any>;
  colunas: TableColumn[];
  colunasOcultas: TableColumn[];
  itensTabelaSelecionados: any[];
  limite: number;
  pagina: number;
  timerFiltro: any;
  timeoutFiltro = 500;

  iniciarPaginacao() {
    this.pagina = 1;
    this.limite = 15;
  }
  limparFiltro(model: any) {
    const keys = Object.keys(model);
    keys.forEach((key) => {
      model[key] = null;
    });
  }
  montarColunasOcultas() {
    this.colunasOcultas = this.colunas.filter((e) => e.hidden);
  }
  onSelect(event: any[]) {
    this.itensTabelaSelecionados = event;
  }
  mostrarOcultarColunas(
      evento: any, colunas = this.colunas,
      colunasOcultas = this.colunasOcultas) {
    if (evento.value.length === 0) {
      colunasOcultas.forEach((colunaOculta) => {
        colunas.find((e) => e.name === colunaOculta.name).hidden = true;
      });
    } else if (evento.value.length === colunasOcultas.length) {
      colunasOcultas.forEach((colunaOculta) => {
        colunas.find((e) => e.name === colunaOculta.name).hidden = false;
      });
    } else {
      colunas.find((e) => e.name === evento.itemValue.name).hidden =
          !evento.itemValue.hidden;
    }
  }
  limparPaginacao() {
    this.pagina = 0;
    this.limite = 0;
  }
}
