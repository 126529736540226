import {Genero} from '@app/shared/enums/genero.enum';

export class UsuarioAuth {
  id: string;
  usuarioId: string;
  alunoId: string;
  treinadorId: string;
  nutricionistaId: string;
  nome: string;
  email: string;
  genero: Genero;

  static from(json: any = {}): UsuarioAuth {
    const usuario = new UsuarioAuth();
    usuario.id = json.id;
    usuario.usuarioId = json.usuarioId;
    usuario.alunoId = json.alunoId;
    usuario.treinadorId = json.treinadorId;
    usuario.nutricionistaId = json.nutricionistaId;
    usuario.nome = json.nome;
    usuario.email = json.email;
    usuario.genero = json.genero;
    return usuario;
  }
}
