import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UsuarioAuth} from '@app/abstraction/domain/entities/usuario-auth.interface';
import {AlterarSenhaModel} from '@app/abstraction/domain/model/alterar-senha.model';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom, map} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private client: HttpClient) {}

  buscarUsuarioLogado() {
    const response$ =
        this.client.get<UsuarioAuth>(API.Parceiro_Auth_Logado)
            .pipe(map(
                (json: UsuarioAuth) => json ? UsuarioAuth.from(json) : null));
    return lastValueFrom(response$);
  }

  login(email: string, senha: string) {
    const response$ =
        this.client.post<string>(API.Parceiro_Auth_Logar, {email, senha})
            .pipe(map((e: any) => e.token));
    return lastValueFrom(response$);
  }

  registrar() {}

  recuperarSenha() {}

  alterarSenha(alterarSenha: AlterarSenhaModel) {
    const response$ =
        this.client.post(API.Parceiro_Auth_Alterar_Senha, alterarSenha);
    return lastValueFrom(response$);
  }
}
