<p-dialog
  header="Lista de exercícios"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <ng-template #imagemTemp pTemplate="body" let-value="value">
    <div class="flex justify-center">
      <img
        *ngIf="value"
        [src]="buscarImagem(value)"
        alt="Imagem do exercício"
        style="min-width: 100px; width: 100px"
      />
    </div>
  </ng-template>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarExerciciosProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-6">
        <app-text-input-ngmodel
          placeholder="Buscar por nome"
          (inputKeydown)="filtrarComTimeout()"
          [(model)]="filtro.nome"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [rows]="dados"
      [selectable]="true"
      selectionMode="single"
      [selecteds]="itemSelecionado"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="fecharModal()" label="Cancelar" [text]="true"></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
