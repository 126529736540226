import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlimentoFiltro} from '@app/abstraction/domain/interfaces/filtros/alimento-filtro.interface';
import {CategoriaFiltro} from '@app/abstraction/domain/interfaces/filtros/categoria-filtro.interface';
import {AlimentoListagem} from '@app/abstraction/domain/interfaces/listagem/alimento-listagem.interface';
import {CategoriaListagem} from '@app/abstraction/domain/interfaces/listagem/categoria-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AlimentoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  buscarTodosAlimentos(filtro?: AlimentoFiltro) {
    const response$ = this.client.get<AlimentoListagem[]>(
        this.geradorQueryString.gerar(`${API.Admin_Alimentos}`, filtro));
    return lastValueFrom(response$);
  }
  buscarTodosCategorias(filtro?: CategoriaFiltro) {
    const response$ = this.client.get<CategoriaListagem[]>(
        this.geradorQueryString.gerar(`${API.Admin_Categorias}`, filtro));
    return lastValueFrom(response$);
  }
}
