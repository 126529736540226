import {Injectable} from '@angular/core';
import {ExercicioService} from '@app/abstraction/data/services/exercicio.service';
import {ExercicioStore} from '@app/abstraction/data/stores/exercicio.store';
import {ExercicioFiltro} from '@app/abstraction/domain/interfaces/filtros/exercicio-filtro.interface';


@Injectable({providedIn: 'root'})
export class ExercicioFacade {
  get exercicios() {
    return this.store.exercicios;
  }
  constructor(
      private store: ExercicioStore,
      private service: ExercicioService,
  ) {}
  async buscarTodos(filtro?: ExercicioFiltro) {
    const exercicios = await this.service.buscarTodos(filtro);
    if (exercicios) {
      this.store.adicionarRange(exercicios);
    }
  }
  limparListaExercicios() {
    this.store.exercicios = null;
  }
}