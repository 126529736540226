export enum Genero {
  Feminino = 1,
  Masculino = 2,
  Outro = 3,
}

export const GeneroLabels = new Map<number, string>([
  [Genero.Feminino, 'Feminino'],
  [Genero.Masculino, 'Masculino'],
  [Genero.Outro, 'Outro'],
]);
